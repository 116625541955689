import React, {useState, useEffect} from "react";
import { useSearchParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Axios from "axios";
import { Checkmark } from "react-checkmark";

import LoaderButton from "./LoaderButton";
import { addDaysToDate } from "./utils/common";

export default function SuccessPage(
    props
) {
    const [session, setSession] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    // const [deliveryDate, setDeliveryDate] = useState(addDaysToDate(new Date(),14));
    const [deliveryDate, setDeliveryDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [orderComplete, setOrderComplete] = useState(false);
    const [docId, setDocId] = useState(props.documentId);
    const [installerName, setInstallerName] = useState("");
    const [installerEmail, setInstallerEmail] = useState("");
    const [managerName, setManagerName] = useState("");
    const [managerEmail, setManagerEmail] = useState("");
    const [showExtraInfo, setShowExtraInfo] = useState(false);
    const [didNotSelectDate, setDidNotSelectDate] = useState(false);
    const [waitList, setWaitList] = useState(false);
    const [bName, setBName] = useState("");
    const [cName, setCName] = useState("");
    const [plastic, setPlastic] = useState(true);

    useEffect(() => {
        let tempDocId = JSON.parse(sessionStorage.getItem('documentId'));
        // console.log('temp',tempDocId);
        if(tempDocId)
            setDocId(tempDocId);
        let tempBName = JSON.parse(sessionStorage.getItem('businessName'));
        if(tempBName)
            setBName(tempBName);
        let tempWaitList = JSON.parse(sessionStorage.getItem('waitList'));
        if(tempWaitList!=null)
            setWaitList(tempWaitList);
        let tempCustomerName = JSON.parse(sessionStorage.getItem('customerName'));
        if(tempCustomerName)
            setCName(tempCustomerName);
        let tempPlastic = JSON.parse(sessionStorage.getItem('plastic'));
        if(tempPlastic!=null)
            setPlastic(true);
    },[]);

    useEffect(() => {
        if(props) {
            console.log(props);
            if(props.documentId)
                setDocId(props.documentId);
            if(props.waitList)
                setWaitList(props.waitList);
            if(props.businessName)
                setBName(props.businessName);
            if(props.customerName)
                setCName(props.customerName);
        }
    },[props]);

    useEffect(() => {
        let stripeSession = searchParams.get("session_id");
        setSession(stripeSession);
    },[searchParams]);

    useEffect(() => {
        if(deliveryDate) {
            setDidNotSelectDate(false);
            if(deliveryDate<(addDaysToDate(new Date(),13))) {
                setShowExtraInfo(true);
            }
        }
    },[deliveryDate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(deliveryDate==null) {
            setDidNotSelectDate(true);
            window.scrollTo(0, 0);
            return;
        }
        setIsLoading(true);
        const data = {
            deliveryDate:deliveryDate,
            documentId:docId,
            sessionId:session,
            businessName:bName,
            customerName:cName,
            installerName:installerName,
            managerName:managerName,
            installerEmail:installerEmail,
            managerEmail:managerEmail
        };
        const url = `${process.env.REACT_APP_PAY_URL}/update-delivery`;
        Axios.post(url,data,{
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(function(response) {
        }).catch(function(error) {
            console.log(error);
        });
        setOrderComplete(true);
        setIsLoading(false);
    }

    return (
        <div>
            {orderComplete ? (
                <div className="text-container-vertical">
                    <div className="text-container">
                        <Checkmark size='xxlarge' color="#131659"/>
                    </div>
                    <br /><br />
                    Thank you for your order!
                    <br /><br />
                    You will receive an email confirmation shortly.
                </div>
            ) : (
                <div className="delivery">
                    {/* {waitList && !plastic && (
                        <div className="warning">
                            You've been placed on the waitlist for your 4-cam system.  We will let you know once they're available.
                        </div>
                    )} */}
                    <div className="text-container-subtitle">
                        Please select a date you would like your cameras delivered.
                    </div>

                    <div className={didNotSelectDate ? "text-container-error" : "text-container"}>
                        <label className="label-success">Date</label>
                        {/* <div className="date-picker"> */}
                            <DatePicker 
                                wrapperClassName='datepicker' 
                                selected={deliveryDate} 
                                onChange={d => setDeliveryDate(d)} 
                                popperPlacement="bottom"
                                placeholderText="Click Here"
                                minDate={addDaysToDate(new Date(),4)}
                                maxDate={addDaysToDate(new Date(),180)}
                            />
                        {/* </div> */}
                    </div>
                    {showExtraInfo ? (
                        <div className="text-container-small">
                            Note: We often carry a two week lead time on new deliveries, due to order volume. We will follow up with you to confirm the delivery date after submitting the order below.
                        </div>
                    ) : (<div></div>)}
                    <br />
                    {/* <div className="text-container-small">
                        Additionally, the following information will help us get you setup<br /><br />
                    </div> */}
                    <div className="text-container-small-left">
                        Who will manage the camera installation (if different from yourself)?
                    </div>
                    <div className="text-container">
                        <label className="label-success">Name</label>
                        <input type="text" id="email" name="installerName" value={installerName} onChange={(e) => setInstallerName(e.target.value)} />
                    </div>
                    <div className="text-container">
                        <label className="label-success">Email</label>
                        <input type="text" id="email" name="installerEmail" value={installerEmail} onChange={(e) => setInstallerEmail(e.target.value)} />
                    </div>
                    <br />
                    <div className="text-container-small-left">
                        Who is the construction manager for the project (if different from above)?
                    </div>
                    <div className="text-container">
                        <label className="label-success">Name</label>
                        <input type="text" id="email" name="managerName" value={managerName} onChange={(e) => setManagerName(e.target.value)} />
                    </div>
                    <div className="text-container">
                        <label className="label-success">Email</label>
                        <input type="text" id="email" name="managerEmail" value={managerEmail} onChange={(e) => setManagerEmail(e.target.value)} />
                    </div>
                    {didNotSelectDate ? (
                        <div className="error-text-container">
                            Please select a target date for delivery
                        </div>
                    ) : (<div />)}
                    <form onSubmit={handleSubmit}>
                        <div className="text-container">
                            <LoaderButton
                                type="submit"
                                isLoading={isLoading}
                            >
                                Submit Your Order
                            </LoaderButton>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}