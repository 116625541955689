// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoaderButton .spinning.glyphicon {
    margin-right: 7px;
    top: 2px;
    animation: spin 1s infinite linear;
  }
  @keyframes spin {
    from { transform: scale(1) rotate(0deg); }
    to { transform: scale(1) rotate(360deg); }
  }`, "",{"version":3,"sources":["webpack://./src/LoaderButton.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,QAAQ;IACR,kCAAkC;EACpC;EACA;IACE,OAAO,gCAAgC,EAAE;IACzC,KAAK,kCAAkC,EAAE;EAC3C","sourcesContent":[".LoaderButton .spinning.glyphicon {\n    margin-right: 7px;\n    top: 2px;\n    animation: spin 1s infinite linear;\n  }\n  @keyframes spin {\n    from { transform: scale(1) rotate(0deg); }\n    to { transform: scale(1) rotate(360deg); }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
