import React, {useEffect, useState} from "react";
import Axios from "axios";

import LoaderButton from "./LoaderButton";
import { useSearchParams } from "react-router-dom";

export default function ESignature({
    setFormState,
    setCustomerEmail,
    customerEmail,
    documentSigned,
    setDocumentSigned,
    setDocumentId,
    fullName,
    setFullName,
    businessName,
    setBusinessName,
    phone,
    setPhone,
    customer
}) {

    const [contractUrl, setContractUrl] = useState(null);
    const [loadiFrame, setLoadiFrame] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        window.addEventListener("message",function(params) {
            if(params.origin !== "https://app.boldsign.com") {
                return;
            } else {
                switch(params.data.action) {
                    case "onDocumentSigned":
                        setLoadiFrame(false);
                        setDocumentSigned(true);
                        setFormState("billingThird");
                        break;
                    // All of the failure cases:
                    default:
                        setLoadiFrame(false);
                        setFormState("productSelectionFirst");
                        break;
                }
            }
        });
    }, [setFormState]);

    useEffect(() => {
        if(customer) {
            if(customer.email) {
                setCustomerEmail(customer.email);
            }
            if(customer.name) {
                setBusinessName(customer.name);
            }
            if(customer.metadata && customer.metadata.name) {
                setFullName(customer.name);
            }
            if(customer.phone) {
                setPhone(customer.phone);
            }
        }
    },[customer]);

    useEffect(() => {
        let bypassId = searchParams.get("bypass_id");
        if(bypassId) {
            async function verifyBypass(id) {
                try {
                    const bypassVerification = await new Promise((resolve,reject) => {
                        const url = `${process.env.REACT_APP_PAY_URL}/bypass-signing`;
                        Axios.post(url,
                            {
                                bypassId:id
                            },
                            {
                                headers:{'Content-Type':'application/json'}
                        }).then(function(response) {
                            resolve(response.data);
                        }).catch(function(error) {
                            reject(error);
                        });
                    });
                    if(bypassVerification && bypassVerification.bypassSigning) {
                        console.log(bypassVerification)
                        setDocumentSigned(true);
                        setCustomerEmail(bypassVerification.email);
                        setFullName(bypassVerification.firstName+" "+bypassVerification.lastName);
                        // setFirstName(bypassVerification.firstName);
                        // setLastName(bypassVerification.lastName);
                    }
                } catch(e) {
                    console.log(e);
                }
            }
            verifyBypass(bypassId);
        }
    },[searchParams])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const signingUrl = await new Promise((resolve,reject) => {
            const url = `${process.env.REACT_APP_PAY_URL}/embedded-signing`;
            console.log(url);
            Axios.post(url,
                {
                    'signerEmail':customerEmail,
                    'fullName':fullName,
                    'businessName':businessName,
                },
                {
                    headers:{'Content-Type':'application/json'}
            }).then(function(response) {
                resolve(response);
            }).catch(function(error) {
                reject(error);
            });
        });
        setContractUrl(signingUrl.data.signLink);
        setDocumentId(signingUrl.data.docId);
        console.log(signingUrl.data.docId);
        setLoadiFrame(true);
        setIsLoading(false);
    }

    const handleAlreadySubmitted = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setFormState("billingThird");
        setIsLoading(false);
    }

    return (
        <div>
            {documentSigned ? (
                <form onSubmit={handleAlreadySubmitted}>
                    <div className="text-container">
                        Thanks for signing our agreement, please proceed to the next page.<br /><br />
                        If you need to review the agreement, please check your email.<br /><br />
                    </div>
                    <div className="text-container">
                        <LoaderButton
                            type="submit"
                            isLoading={isLoading}
                        >
                            Continue to Checkout
                        </LoaderButton>
                    </div>
                </form>
            ) : 
                loadiFrame && contractUrl ? (
                    <iframe title="boldsign" src={contractUrl} className="signer" />
                ) : (
                <form onSubmit={handleSubmit}>
                    <div className="text-container">
                        Let's get started with your name and email so that we can load up our agreement
                    </div>
                    <div style={{paddingTop:"20px"}}></div>
                    <div className="text-container">
                        <div style={{width:"100%"}}>
                            <label>Full Name</label>
                            <input type="text" id="email" name="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                        </div>
                        <div style={{paddingLeft:"20px"}}></div>
                        <div style={{width:"100%"}}>
                            <label>Business Name</label>
                            <input type="text" id="email" name="businessName" value={businessName} onChange={(e) => setBusinessName(e.target.value)} />
                        </div>
                    </div>
                    <div className="text-container">
                        <div style={{width:"100%"}}>
                            <label>Phone</label>
                            <input type="tel" id="email" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div style={{paddingLeft:"20px"}}></div>
                        <div style={{width:"100%"}}>
                            <label>Email</label>
                            <input type="email" id="email" name="customerEmail" value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />
                        </div>
                    </div>
                    <div style={{paddingBottom:"20px"}}></div>
                    <div className="text-container">
                        <LoaderButton 
                            type="submit"
                            isLoading={isLoading}
                            disabled={
                                (fullName && fullName.length === 0) ||
                                (customerEmail && customerEmail.length === 0) ||
                                !customerEmail || !fullName
                            }
                        >
                            Load the Agreement
                        </LoaderButton>
                    </div>
                </form>
            )}
        </div>
    )
}