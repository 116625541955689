import React, { useState, useEffect } from "react";
import { Glyphicon, Button } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import Axios from "axios";

export default function ProductSelector({
        setFormState,
        setOrderQuantity,
        orderQuantity,
        setOrderQuantity2,
        orderQuantity2,
        setCustomer,
        setPlasticCams
    }) {
    const [fourCamPrice, setFourCamPrice] = useState(400);
    const [twoCamPrice, setTwoCamPrice] = useState(250);
    const [price, setPrice] = useState(0);
    // const [price2, setPrice2] = useState(twoCamPrice);
    const [negDisable, setNegDisable] = useState(true);
    const [posDisable, setPosDisable] = useState(false);
    const [negDisable2, setNegDisable2] = useState(true);
    const [posDisable2, setPosDisable2] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [uniqueProduct, setUniqueProduct] = useState(null);
    const [customerFillingOutInfo,setCustomerFillingOutInfo] = useState(null);
    const [plastic, setPlastic] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormState("eSignatureSecond");
    }

    const updateQuantity = async (up,fCam=true) => {
        if(fCam) {
            if(up) {
                if(orderQuantity<10) {
                    setOrderQuantity(orderQuantity+1);
                }
            } else {
                if(orderQuantity>0) {
                    setOrderQuantity(orderQuantity-1);
                }
            }
        } else {
            if(up) {
                if(orderQuantity2<10) {
                    setOrderQuantity2(orderQuantity2+1);
                }
            } else {
                if(orderQuantity2>0) {
                    setOrderQuantity2(orderQuantity2-1);
                }
            }
        }
    }

    useEffect(() => {
        async function verifyProductId(productId) {
            try {
                const stripeVerification = await new Promise((resolve,reject) => {
                    const url = `${process.env.REACT_APP_PAY_URL}/verify-product-id`;
                    Axios.post(url,
                        {
                            productId:productId
                        },
                        {
                            headers:{'Content-Type':'application/json'}
                    }).then(function(response) {
                        resolve(response.data);
                    }).catch(function(error) {
                        reject(error);
                    });
                });
                if(stripeVerification && stripeVerification.price)
                    setUniqueProduct(stripeVerification);
            } catch(e) {
                console.log(e);
            }
        }
        async function verifyCustomerId(customerId) {
            try {
                const customerVerification = await new Promise((resolve,reject) => {
                    const url = `${process.env.REACT_APP_PAY_URL}/verify-customer-id`;
                    Axios.post(url,
                        {
                            customerId:customerId,
                        },{
                            headers:{'Content-Type':'application/json'}
                        }).then(function(response) {
                            resolve(response.data);
                        }).catch(function(error) {
                            reject(error);
                        });
                });
                // console.log(customerVerification);
                if(customerVerification && customerVerification.id && plastic==false) {
                    setCustomerFillingOutInfo(customerVerification);
                    setCustomer(customerVerification);
                } else if(customerVerification && customerVerification.id && plastic) {
                    setCustomerFillingOutInfo(customerVerification);
                    setCustomer(customerVerification);
                }
            } catch(e) {
                console.log(e);
            }
        }
        let stripeProductKey = searchParams.get("product_id");
        let customerKey = searchParams.get("customer_id");
        let plasticKey = searchParams.get("v1");
        if(stripeProductKey) {
            verifyProductId(stripeProductKey);
        }
        if(plasticKey) {
            setPlastic(true);
        }
        if(customerKey) {
            setCustomerFillingOutInfo(customerKey);
            verifyCustomerId(customerKey);
        }
    },[searchParams]);

    useEffect(() => {
        console.log("Unique",uniqueProduct)
    },[uniqueProduct])

    useEffect(() => {
        if(customerFillingOutInfo && customerFillingOutInfo.id && plastic==false) {
            setFourCamPrice(400);
        } else if(customerFillingOutInfo && customerFillingOutInfo.id) {
            setFourCamPrice(350);
        } else if(!customerFillingOutInfo) {
            setOrderQuantity2(0);
        }
    },[customerFillingOutInfo])

    useEffect(() => {
        setOrderQuantity(orderQuantity);
        setPrice(fourCamPrice*orderQuantity+(twoCamPrice*orderQuantity2));
        if(orderQuantity>=9) {
            setPosDisable(true);
            setOrderQuantity(9);
        } else {
            setPosDisable(false);
        }
        if(orderQuantity>=5) {
            if(customerFillingOutInfo) {
                setPosDisable(true);
            }
        } else {
            if(customerFillingOutInfo) {
                setPosDisable(false);
            }
        }
        if(orderQuantity<=0) {
            setNegDisable(true);
            setOrderQuantity(0);
        } else {
            setNegDisable(false);
        }

    },[orderQuantity]);

    useEffect(() => {
        if(plastic) setFourCamPrice(350);
        setPlasticCams(plastic);
    },[plastic]);

    useEffect(() => {
        setPrice(fourCamPrice*orderQuantity+(twoCamPrice*orderQuantity2));
    },[fourCamPrice,twoCamPrice]);

    useEffect(() => {
        setOrderQuantity2(orderQuantity2);
        setPrice(fourCamPrice*orderQuantity+(twoCamPrice*orderQuantity2));
        if(orderQuantity2>=9) {
            setPosDisable2(true);
            setOrderQuantity2(9);
        } else {
            setPosDisable2(false);
        }
        if(orderQuantity2>=5) {
            if(customerFillingOutInfo) {
                setPosDisable2(true);
            }
        } else {
            if(customerFillingOutInfo) {
                setPosDisable2(false);
            }   
        }
        if(orderQuantity2<=0) {
            setNegDisable2(true);
            setOrderQuantity2(0);
        } else {
            setNegDisable2(false);
        }
    },[orderQuantity2]);

    return (
        <div className="product">
            <div className="image-container">
                <img src={plastic ? "https://i.imgur.com/h540yTY.png" : "https://i.imgur.com/w1LKNdf.png" } alt="cap" />
                {/* "https://i.imgur.com/g0Y0Oq4.png" */}
            </div>
            <div className="text-container">
                <div className="product-description">
                    {customerFillingOutInfo ? (<div>Thank you for using our $1 IBS promotion for your 1st month of service!</div>) : "The Construction Access Point gives you complete control of your jobsite"}
                    {!customerFillingOutInfo && (
                        <div className="text-container">
                            <ul id="double">
                                <li>24/7 Live Check-ins</li>
                                <li>24/7 Theft Deterrence</li>
                                <li>24/7 Motion Recording</li>
                                <li>Wide Angle Viewing</li>
                                <li>Ulimited Storage</li>
                                <li>Unlimited Accounts</li>
                                <li>Battery Backup</li>
                                <li>Free Timelapse</li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
            {uniqueProduct ? (
                <div className="price-container">
                    <div className="price">
                        ${
                            orderQuantity+orderQuantity2>5 ? orderQuantity>0 ?
                                ((Math.min(orderQuantity+orderQuantity2-5,orderQuantity)*fourCamPrice)+(Math.max(0,orderQuantity2-5)*twoCamPrice))+(5*Math.round(uniqueProduct.price.unit_amount/100.)) : 
                                Math.max(0,orderQuantity2-5)*twoCamPrice+(5*Math.round(uniqueProduct.price.unit_amount/100.)) :
                                (orderQuantity+orderQuantity2)*Math.round(uniqueProduct.price.unit_amount/100.)
                        }
                    </div>
                    <div className="per-month">
                        For the first month<br />
                        ${price}/month after
                    </div>
                </div>
            ) : 
                !customerFillingOutInfo && (
                    <div className="price-container">
                        <div className="price">
                            ${price}
                        </div>
                        <div className="per-month">
                            per month
                        </div>
                    </div>
                )
            }
            <form onSubmit={handleSubmit}>
                <div className="text-container-large">
                    How many camera systems do you need?
                </div>
                
                <div className="text-container-small">
                    {customerFillingOutInfo? orderQuantity===5? (<div>IBS discount maxes at 5 CAPs<br />Contact us if you need more</div>):"" : uniqueProduct? "(We limit the $1 promo to 5 CAPs)" : "(you can change this later)"}
                </div>
                <div className="text-container-large">
                    4-Camera @ ${fourCamPrice}/month
                </div>
                <div className="selection">
                    <Button bsSize="small" disabled={negDisable} onClick={(e) => updateQuantity(false)}>
                        <Glyphicon glyph="minus" />
                    </Button>
                    <input type="number" disabled={true} name="qty" value={orderQuantity} onChange={(e) => setOrderQuantity(e.target.value)} />
                    <Button bsSize="small" disabled={posDisable} onClick={(e) => updateQuantity(true)}>
                        <Glyphicon glyph="plus" />
                    </Button>
                </div>
                {/* {!plastic && orderQuantity>0 && (
                    <div className="warning">
                        <p><b>Signing up for a four camera unit will put you on a waitlist.&nbsp;
                            <a href={customerFillingOutInfo ? process.env.REACT_APP_SELF_URL+"?customer_id="+customerFillingOutInfo.id+"&v1=true" : process.env.REACT_APP_SELF_URL+"?v1=true"}>
                                Click Here
                            </a> 
                            &nbsp;if you would like to subscribe to our older 4-camera models @ $350/month.</b></p>
                    </div>
                )} */}
                <div className="text-container-large">
                    2-Camera @ ${twoCamPrice}/month
                </div>
                <div className="selection">
                    <Button bsSize="small" disabled={negDisable2} onClick={(e) => updateQuantity(false,false)}>
                        <Glyphicon glyph="minus" />
                    </Button>
                    <input type="number" disabled={true} name="qty" value={orderQuantity2} onChange={(e) => setOrderQuantity2(e.target.value)} />
                    <Button bsSize="small" disabled={posDisable2} onClick={(e) => updateQuantity(true,false)}>
                        <Glyphicon glyph="plus" />
                    </Button>
                </div>
                <div className="text-container-small">
                    Click below to sign our standard agreement and {customerFillingOutInfo ? "enter your shipping info." : "make your first month's payment."}
                </div>
                <div className="text-container">
                    <button type="submit" disabled={orderQuantity<=0 && orderQuantity2<=0}>{customerFillingOutInfo ? "Continue" : "Signup to Monitor your Sites Now"}</button>
                </div>
            </form>
        </div>
    )
}